import React, { useState } from 'react';
import "../../styles/index.scss";
import { useNavigate } from 'react-router-dom';


const Connecter = () => {
    const [utilisateur, setUtilisateur] = useState('');
    const [motpasse, setMotpasse] = useState('');
    const navigate = useNavigate();


    const handleLogin = (event) => {
        event.preventDefault();

        if (motpasse === "w_TeamBelo" && utilisateur === "wadmin") {
            navigate("/pointage")
        } else {
            alert("Erreur sur utilisateur ou le mot de passe");
        }

    };
    return (
        <div className='wrapper log-container'>
            <header>
                <h2>Connection</h2>
            </header>
            <form action='' onSubmit={handleLogin} id="connecter" className='form '>
                <div className='input'>
                    <input
                        id='utilisateur'
                        type='text'
                        onChange={(e) => setUtilisateur(e.target.value)}
                        placeholder='utilisateur' />
                </div>
                <div className='utilisateur error' style={{ color: 'black' }}></div>
                <div className='input'>
                    <input
                        id="motpasse"
                        type='password'
                        onChange={(e) => setMotpasse(e.target.value)}
                        placeholder='Mot de passe' />
                </div>
                <div className='password error' style={{ color: 'black' }}></div>
                <button type='submit' className='button' style={{ width: "100%" }}>Connecter</button>
            </form>
        </div>
    );
};

export default Connecter;