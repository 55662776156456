import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Utilisateurs from '../../pages/Utilisateurs/Utilisateurs';
import Inscription from '../../pages/Inscription/Inscription';
import Pointage from '../../pages/Pointage/Pointage';
import Connecter from '../../pages/Connecter/Connecter';
import Modifier from '../../pages/Modifier/Modifier';
import Uploads from '../../pages/Uploads/Uploads';

const router = createBrowserRouter([
    
    {
        path:'/',
        element:<Connecter/>
    },
   
    {
        path:'/inscription',
        element:<Inscription/>
    },
    {
        path:'/uploads/:id',
        element:<Uploads/>
    },
    {
        path:'/pointage',
        element:<Pointage/>
    },
    {
        path: '/utilisateur',
        element: <Utilisateurs />
    },
    {
        path: '/utilisateur/:id',
        element: <Modifier />
    },
])

const index = () => {
    return <RouterProvider router={router} />
};

export default index;