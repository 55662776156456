import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import "../../styles/index.scss";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import Dayjs from 'react-dayjs';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

const Pointage = () => {

    const componentPDF = useRef();
    const [pointage, setPointage] = useState([]);
    const [allPointage, setAllPointage] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    // const [query, setQuery] = useState("")

    useEffect(() => {
        axios.get('http://localhost:5000/pointage')
            .then(res => {
                setPointage(res.data);
                setAllPointage(res.data);
            });
    }, [])


    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: 'Pointage',
        // onAfterPrint: () => alert('Enregistrement reussi!!!')
    })

    const handleSelect = (date) => {
        let filtered = allPointage.filter((pointage) => {
            let pointageDate = new Date(pointage.dateenreg);
            return (
                pointageDate >= date.selection.startDate &&
                pointageDate <= date.selection.endDate
            );
        });
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
        setPointage(filtered);
    }
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
    }

    return (
        <>
            <div className="container grid-cols-4">

                <div className="paddings innerWidth ">
                    <div className="r-head flexColStart table recherche">
                        <span className="greenText" style={{ marginTop: '-10%', marginBottom: '2%' }}>Pointage</span>
                        <button type='submit' className='button' id='button-inscription'>
                            <Link to="/utilisateur">Liste des personnels</Link>
                        </button>
                        {/* <input
                            type="text"
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder='Rechercher'
                            // className='recherche'
                            style={{marginTop:'5%'}}
                        /> */}
                        <div style={{ width: '80%' }}>
                            <DateRangePicker
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                            />
                        </div>


                        <button
                            type='submit'
                            className='buttonCat'
                            onClick={generatePDF}

                        >
                            <LocalPrintshopIcon />
                        </button>
                    </div>
                    <div ref={componentPDF} style={{ width: '100%' }}>
                        <form action=''>
                            <Table style={{ marginTop: '-40%' }}>
                                <TableHead>
                                    <TableRow >
                                        <TableCell
                                            style={{
                                                fontSize: "15px",
                                                background: "#3c72e6",
                                                color: 'white',
                                                border:'2px solid white'
                                            }}
                                        >
                                            Identifiant
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "15px",
                                                background: "#3c72e6",
                                                color: 'white',
                                                border:'2px solid white'
                                            }}
                                        >
                                            Entree
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                ontSize: "15px",
                                                background: "#3c72e6",
                                                color: 'white',
                                                border:'2px solid white'
                                            }}
                                        >
                                            Sortie
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "15px",
                                                background: "#3c72e6",
                                                color: 'white',
                                                border:'2px solid white'
                                            }}
                                        >
                                            Aleatoire
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "15px",
                                                background: "#3c72e6",
                                                color: 'white',
                                                border:'2px solid white'
                                            }}
                                        >
                                            Enregistrement
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pointage && pointage.map((pointage) => {
                                        let date = new Date(pointage.dateenreg);
                                        return (
                                            <TableRow key={pointage.id}>
                                                <TableCell>{pointage.identifiant}</TableCell>
                                                <TableCell>
                                                    <Dayjs format="DD-MM-YYYY">
                                                        {pointage.dateentree}
                                                    </Dayjs>
                                                    {' '}/{' '}
                                                    <Dayjs format="HH:mm:ss">
                                                        {pointage.heureentree}
                                                    </Dayjs>

                                                </TableCell>
                                                <TableCell>
                                                    <Dayjs format="DD-MM-YYYY">
                                                        {pointage.datesortie}
                                                    </Dayjs>
                                                    {' '}/{' '}
                                                    <Dayjs format="HH:mm:ss">
                                                        {pointage.heuresortiee}
                                                    </Dayjs>
                                                </TableCell>
                                                <TableCell>
                                                    <Dayjs format="DD-MM-YYYY">
                                                        {pointage.datealeatoire}
                                                    </Dayjs>
                                                    {' '}/{' '}
                                                    <Dayjs format="HH:mm:ss">
                                                        {pointage.heurealeatoire}
                                                    </Dayjs>

                                                </TableCell>
                                                <TableCell>
                                                    <Dayjs format="DD-MM-YYYY">
                                                        {date}
                                                    </Dayjs>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </form>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Pointage;

