import React from "react";
// import Header from './pages/Header/Header'
import Routes from './components/Router';
function App() {
  return (
    <div>
      {/* <Header/> */}
      <Routes />
    </div>
  );
}

export default App;
