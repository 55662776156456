import React, { useEffect, useState } from 'react';
import "../../styles/index.scss";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import axios from 'axios';



const Utilisateurs = () => {
    const [query, setQuery] = useState("")
    const [utilisateur, setUtilisateur] = useState([]);

    useEffect(() => {
        axios.get('http://localhost:5000/utilisateur')
            .then((res) => setUtilisateur(res.data))
            .catch(err => console.log(err))
    }, []);


    return (
        <>
            <div className="container grid-cols-4">

                <div className="paddings innerWidth ">
                    <div className="r-head flexColStart table recherche">
                        <span className="greenText">Utilisateurs</span>
                        <button type='submit' className='button' id='button-inscription'>
                            <Link to="/inscription">Nouveau</Link>
                        </button>
                        <button type='submit' className='button' id='button-inscription'>
                            <Link to="/pointage">Pointage</Link>
                        </button>
                        <input
                            type="text"
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder='Rechercher'
                            // style={{marginBottom:'-100%'}}
                        />
                    </div>
                    <form action=''>
                        <Table style={{marginTop:'-40%'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>photo</TableCell>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Identification</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Statut</TableCell>
                                    <TableCell>Etat</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {utilisateur.filter((post) =>
                                    post.identifiant.toLowerCase().includes(query)
                                ).map((user, index) => (
                                    <TableRow key={index}>
                                        <TableCell><Link to={`/uploads/${user.id}`}><img src={user.file} alt='pic' width={80} style={{ borderRadius: '5%' }} /></Link></TableCell>
                                        <TableCell>{user.nom}</TableCell>
                                        <TableCell>{user.identifiant}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.statut}</TableCell>
                                        <TableCell><Link to={`/utilisateur/${user.id}`}><button className='button'>{user.etat}</button></Link></TableCell>
                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </form>

                </div>
            </div >
        </>
    );
};

export default Utilisateurs;