import React, { useState } from 'react';
import axios from 'axios';
import "../../styles/index.scss";
import { Link } from 'react-router-dom';
import {
    useNavigate,
    useParams
} from 'react-router-dom';

const Uploads = () => {
    const [file, setFile] = useState('')
    const { id } = useParams();
    const navigate = useNavigate();

    const handleFile = (e) => {
        setFile(e.target.files[0])
    }

    const handleUpdate = (event) => {
        event.preventDefault();

        const formdata = new FormData();
        formdata.append('image', file);

        axios.put('http://localhost:5000/uploads/' + id, formdata, { file })
            .then(res => {
                console.log(res.data.data);
                navigate("/utilisateur");
            })
            .catch(err => console.log(err));
    }
    return (
        <div className='wrapper log-container'>
            <header>
                <h2>Image</h2>
            </header>
            <form action='' onSubmit={handleUpdate} id="connecter" className='form '>

                <div className='input-box'>
                    <input
                        id="file"
                        type='file'
                        placeholder='Inserer le photo ici'
                        onChange={handleFile}
                    />
                </div>
                <button type='submit' className='button' style={{ width: "100%" }}>Modifier</button>
                <span>
                    <Link to={'/utilisateur'} style={{ color: "#3c72e6", marginRight:"10%" }}>Liste utilisateur</Link>
                    <Link to={'/pointage'} style={{ color: "#3c72e6", marginRight:"10%" }}>Pointage</Link>
                    <Link to={'/inscription'} style={{ color: "#3c72e6" }}>Nouveau</Link>
                </span>
            </form>
        </div >
    );
};

export default Uploads;