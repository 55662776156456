import React, { useState } from 'react';
import axios from 'axios';
import "../../styles/index.scss";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate, useParams } from 'react-router-dom';

const Modifier = () => {
    const [etat, setEtat] = useState('');
    const Active = "Active";
    const Desactive = "Desactive";
    const { id } = useParams();
    const navigate = useNavigate();

    const handleUpdate = (event) => {
        event.preventDefault();
        axios.put('http://localhost:5000/utilisateur/'+id, {etat})
            .then(res => {
                console.log(res.data.data);
                navigate("/utilisateur")
            })
            .catch(err => console.log(err));
    }
    return (
        <div className='wrapper log-container'>
            <header>
                <h2>Mise à jour</h2>
            </header>
            <form action='' onSubmit={handleUpdate} id="connecter" className='form '>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, maxHeight: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Etat</InputLabel>
                    <Select
                        onChange={(e) => setEtat(e.target.value)}
                        style={{ height: "50px", width:"100%" }}>
                        <MenuItem value={Active}>Actif</MenuItem>
                        <MenuItem value={Desactive}>Inactif</MenuItem>
                    </Select>
                </FormControl>
                <button type='submit' className='button' style={{ width: "100%" }}>Modifier</button>
            </form>
        </div >
    );
};

export default Modifier;