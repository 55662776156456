import React, { useState } from 'react';
import "../../styles/index.scss";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Inscription = () => {
    const [identifiant, setIdentifiant] = useState('');
    const [nom, setNom] = useState('');
    const [email, setMail] = useState('');
    const [statut, setStatut] = useState('');
    const [motpasse, setMotpasse] = useState('');
    
    const navigate = useNavigate();    

    const handleRegister = (event) => {
        event.preventDefault();
        axios.post('http://localhost:5000/inscription'
            , { identifiant, motpasse, email, nom, statut })
            .then(res => {
                console.log(res.data.data);
                navigate("/utilisateur");
            })
            .catch(err => console.log(err));

    }

    return (
        <div className='wrapper log-container'>
            <header>
                <h2>Inscription</h2>
            </header>

            <form action='' onSubmit={handleRegister} id='from-register'>
                <div className='input-box'>
                    <input
                        id="Identifiant"
                        type='text'
                        placeholder='Identifiant'
                        onChange={e => setIdentifiant(e.target.value)}
                    />
                </div>
                <div className='input-box'>
                    <input
                        id="nom"
                        type='text'
                        placeholder='Nom'
                        onChange={e => setNom(e.target.value)}
                    />
                </div>
                <div className='input-box'>
                    <input
                        id="email"
                        type='text'
                        placeholder='e-mail'
                        onChange={e => setMail(e.target.value)}
                    />
                </div>
                <div className='input-box'>
                    <input
                        id="statut"
                        type='text'
                        placeholder='Statut'
                        onChange={e => setStatut(e.target.value)}
                    />
                </div>
                <div className='input-box'>
                    <input
                        id="motpasse"
                        type='password'
                        placeholder='Mot de passe'
                        onChange={e => setMotpasse(e.target.value)}
                    />
                </div>
                <button type='submit' className='button' id='button-inscription'>Enregistrer</button>
            </form>
        </div >

    );
};

export default Inscription;